import Aether from "./components/Aether";
import Hero from "./components/Hero";
import Updates from "./components/Updates";

export default function App() {
  return (
    <div className="container-fluid">
      <Hero />
      <Aether />
      <Updates />
    </div>
  );
}

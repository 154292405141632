import { useState } from "react";

export default function Updates() {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    organization: "",
    website: "",
    comment: "",
  });

  const mailData = {
    list: "https://findinggalileo.us1.list-manage.com/subscribe/post",
    u: "805e834246003d35d0398947f",
    id: "0dbcb0378f",
    spam: "b_805e834246003d35d0398947f_0dbcb0378f",
  };

  return (
    <div className="d-flex flex-column vh-100" id="updates">
      <h1 className="display-1 text-center mt-5">Join Us!</h1>
      <form
        className="d-flex flex-column m-5"
        action={mailData.list}
        method="POST"
        noValidate
      >
        <input type="hidden" name="u" value={mailData.u} />
        <input type="hidden" name="id" value={mailData.id} />
        <div className="row mb-2 g-1">
          <div className="col">
            <div className="form-floating mb-2">
              <input
                className="form-control bg-transparent"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                value={formData.email}
                placeholder="Email address"
                type="email"
                name="EMAIL"
                id="MERGE0"
              />
              <label htmlFor="email">Email address</label>
            </div>

            <div className="form-floating mb-2">
              <input
                className="form-control bg-transparent"
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                value={formData.name}
                placeholder="Name"
                type="text"
                name="MERGE6"
                id="MERGE6"
              />
              <label htmlFor="MERGE6">Name</label>
            </div>
            <div className="form-floating mb-2">
              <input
                className="form-control bg-transparent"
                onChange={(e) =>
                  setFormData({ ...formData, organization: e.target.value })
                }
                value={formData.organization}
                placeholder="Organization"
                type="text"
                name="MERGE7"
                id="MERGE7"
              />
              <label htmlFor="MERGE7">Organization</label>
            </div>
            <div className="form-floating mb-2">
              <input
                className="form-control bg-transparent"
                onChange={(e) =>
                  setFormData({ ...formData, website: e.target.value })
                }
                value={formData.website}
                placeholder="Website"
                type="text"
                name="MERGE5"
                id="MERGE5"
              />
              <label htmlFor="MERGE5">Website</label>
            </div>
            <div className="form-floating mb-2">
              <input
                className="form-control bg-transparent"
                onChange={(e) =>
                  setFormData({ ...formData, comment: e.target.value })
                }
                value={formData.comment}
                placeholder="Comment"
                type="text"
                name="MERGE8"
                id="MERGE8"
              />
              <label htmlFor="MERGE8">Comment</label>
            </div>
          </div>
        </div>

        <input
          type="submit"
          value="Sign up for updates"
          className="btn btn-transparent border px-5 mb-5"
        />

        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
          aria-label="Please leave the following field empty"
        >
          <label htmlFor={mailData.spam}>Name:</label>
          <input
            type="text"
            name={mailData.spam}
            tabIndex="-1"
            defaultValue=""
          />
        </div>
      </form>
      <div className="text-center mt-auto mb-1">
        <a href="#home" className="link-light">
          <i className="bi bi-chevron-up"></i>
        </a>
      </div>
    </div>
  );
}

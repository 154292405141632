import heroBackground from "../assets/galileo512.png";

export default function Hero() {
  return (
    <div
      className="d-flex flex-column vh-100"
      id="home"
      style={{
        background: `url("${heroBackground}") no-repeat center center/contain`,
      }}
    >
      <div className="d-flex flex-column h-100 w-100 align-items-center justify-content-center mt-5">
        <h1 className="display-1 mt-5">Finding Galileo</h1>

        <p className="display-6 mt-auto mb-5 mx-md-5 text-center">
          Who will challenge the long-established but undeniably incomplete
          fundamental theories of physics and inspire the next paradigm shift?
        </p>
        <div className="text-center mb-3">
          <a href="#aether" className="link-light">
            <i className="bi bi-chevron-down"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

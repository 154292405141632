export default function Aether() {
  return (
    <div
      className="d-flex flex-column min-vh-100 align-items-center"
      id="aether"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/2/2a/Quantum_Fluctuations.gif"
        alt="aether"
        className="img-fluid mt-5"
      />

      <div className="my-5 mx-md-5">
        <h5 className="display-6 text-center">Back to the Aether?</h5>
        <p className="lead">
          For 100 years, modern physics has been stuck with two irreconcilable
          paradigms: The physics of the stars, and the physics of atoms.
          Physics, the queen of sciences, has been an extraordinary mathematical
          success enabling technological miracles, but a disaster at explaining
          reality.
        </p>
        <p className="lead">
          Physics remains at an impasse in providing a unified explanation of
          the fundamental forces that hold our world together: gravity,
          electromagnetism, and the nuclear forces. Everyone agrees that
          something is missing, perhaps even at the foundations of modern
          physics. Even Einstein knew he had missed something important, that
          his work was incomplete.
        </p>
        <p className="lead">
          Before the modern era of physics, the leading scientists believed that
          empty space was full of an invisible medium they called Aether, and
          the invisible forces of gravity and electromagnetism could be
          explained by the flows of this medium. For most of the past century,
          however, the very idea of Aether has been taboo.
        </p>
        <p className="lead">
          Today there is a growing consensus in physics that empty space is not
          empty after all, but full of invisible energy. Could this energy
          indeed be responsible for the fundamental forces of physics? Could
          this energy hold the key to the grand unification that eluded Einstein
          and everyone else to this day?
        </p>
        <p className="lead">
          What happens if we go back to the time of Aether, armed with our
          extraordinary modern technology, and imagine a different path forward?
          Can we find a new Galileo who sees what everyone else has missed, who
          finds a new way to unify physics and explain our reality?
        </p>
      </div>
      <div className="text-center mb-3">
        <a href="#updates" className="link-light">
          <i className="bi bi-chevron-down"></i>
        </a>
      </div>
    </div>
  );
}
